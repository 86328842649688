<template>
    <v-card>
      <v-navigation-drawer
        class="bg-deep-purple"
        color="secondary"
        permanent
      >
        <v-list-item class="pt-10 px-5">
            <Logo></Logo>
        </v-list-item>

        <v-list class="px-5 pt-10">
            <v-list-item
                v-for="sidebarRoute in routes"
                color="base50"
                base-color="base50"
                :active="route.name == `/${sidebarRoute.name}` || route.name && route.name.includes(sidebarRoute.relatedRoutes)"
                :title="sidebarRoute.title"
                ripple
                rounded="lg"
                @click="navigateTo(sidebarRoute.name)"
            >
                <template v-slot:prepend="{isActive}">
                    <v-icon :color="isActive ? 'primary' : 'base400'" :icon="route.name == `/${sidebarRoute.name}` || route.name && route.name.includes(sidebarRoute.relatedRoutes) ? sidebarRoute.selectedIcon : sidebarRoute.icon"></v-icon>
                </template>
            </v-list-item>
        </v-list>

        <template v-slot:append>
            <div class="pa-5">
                <v-list-item
                    class="pa-5"
                    prepend-icon="mdi-logout"
                    :title="$t('logout')"
                    @click="logout"
                    v-ripple
                ></v-list-item>
            </div>
        </template>
      </v-navigation-drawer>
  </v-card>
</template>

<script setup>
import Logo from '@components/Logo'
import { useUserStore } from '@/store/user';
import router from '@/router'
import { useRoute } from 'vue-router'
import {useI18n} from "vue-i18n";
import { watch } from 'vue';

const route = useRoute();
const i18n = useI18n();

const userStore = useUserStore();
const { logoutUser } = userStore;

const routes = [
    {
        name: 'installations',
        relatedRoutes: 'installation',
        icon: '$installations',
        selectedIcon: '$installations_selected_sidebar',
        title: i18n.t('bottomNav.installations')
    },
    {
        name: 'loyalty',
        relatedRoutes: 'loyalty',
        icon: 'mdi-gift-outline',
        selectedIcon: 'mdi-gift-outline',
        title: i18n.t('bottomNav.loyalty')
    },
    {
        name: 'support',
        relatedRoutes: 'support',
        icon: '$support',
        selectedIcon: '$support_selected',
        title: i18n.t('bottomNav.support')
    },
    {
        name: 'account',
        relatedRoutes: 'account',
        icon: '$account',
        selectedIcon: '$account_selected',
        title: i18n.t('bottomNav.account')
    },
    {
        name: 'notifications',
        relatedRoutes: 'notifications',
        icon: 'mdi-bell-outline',
        selectedIcon: 'mdi-bell-outline',
        title: i18n.t('bottomNav.notifications')
    }
]

function navigateTo(path) {
    router.push({ name: `${path}`})
}

function logout(){
    logoutUser();
}

</script>

<style scoped>

.noOpacity {
    opacity: 100 !important;
    color: black;
}

</style>
