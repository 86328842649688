export const localStorageKeys = {
    user: "User",
    installations: "Installations",
    prospects: "prospects"
};

export function getLocalstorageItem(key) {
    let item = JSON.parse(localStorage.getItem(key));
    if(item){
        const now = new Date();
        const expDate = new Date(item.expDate);
        if(now > expDate){
            return undefined;
        }
        else{
            return item[key];
        }
    }
    return undefined;
}

export function hasToken(){
    return getLocalstorageItem(localStorageKeys.user) !== undefined;
}

export function setLocalstorageItem(key, item, daysTillExpiration = 1) {
    let date = new Date(Date.now() + (daysTillExpiration * 24 * 3600000));
    localStorage.setItem(
        key,
        JSON.stringify(
            {
                ...{ expDate: date },
                ...{ [key]: item }
            }
        )
    );
}

export function removeLocalstorageItem(key) {
    localStorage.removeItem(key);
}

export function emptyLocalstorage() {
    for(const key in localStorageKeys){
        localStorage.removeItem(localStorageKeys[key]);
    }
}
