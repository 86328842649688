<template>
    <v-dialog v-model="isDialogOpen" width="auto">
        <v-card>
            <v-card-title>{{ $t('pushNotifications.dialogTitle') }}</v-card-title>
            <v-card-text>
                {{ $t('pushNotifications.dialogBody') }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="declineNotifications">
                    {{ $t('pushNotifications.decline') }}
                </v-btn>
                <v-btn variant="elevated" color="primary" @click="subscribeToNotifications">
                    {{ $t('pushNotifications.accept') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { urlBase64ToUint8Array } from '@/helpers/util';
import { useCustomerStore } from '@/store/customer';
import { useNotificationStore } from "@/store/notifications";

const { addNotification, notificationAction } = useNotificationStore();

const customerStore = useCustomerStore();

let isDialogOpen = ref(false);

onMounted(async () => {
    if (customerStore.arePushNotificationsDeclined()) {
        return;
    }

    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.getSubscription();
    if (!subscription) {
        isDialogOpen.value = true;
    }

    addPostMessageListener();
});

const declineNotifications = () => {
    isDialogOpen.value = false;
    customerStore.declinePushNotifications();
}

const subscribeToNotifications = async () => {
    isDialogOpen.value = false;

    const vapidPublicKey = import.meta.env.VITE_VAPID_PUBLIC_KEY;
    try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
        });

        customerStore
            .registerSubscription(subscription)
            .catch(console.error);
    } catch (e) {
       console.warn('Unable to register subscription.', e);
    }
}

const addPostMessageListener = () => {
    navigator.serviceWorker.addEventListener("message", (event) => {
        if(event.data.event === 'push') {
            addNotification(event.data.payload);
        } else if (event.data.event === 'click') {
            notificationAction(event.data.payload)
        }
    });
}
</script>
