import { createApp } from 'vue'
import { registerPlugins } from '@/plugins'
import { initKeycloak } from '@/helpers/keycloak';
import { getSubdomain } from './helpers/util';
import App from './App.vue'

const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.substring(1);
}

const getHostUrl = () => {
    const hostparts = window.location.href.split('happit.app')
    let hosturl = `${hostparts[0]}happit.app`;
    return import.meta.env.VITE_NAME === 'local' ? 'http://localhost:3000' : hosturl
}

const getManifest = () => {
    let hosturl = getHostUrl();
    let manifest = {
        "start_url": `${hosturl}/installations`,
        "display": "standalone",
        "background_color": "#ffffff",
        "lang": "en",
        "scope": `${hosturl}/`,
        "theme_color": "#ffffff",
    }

    const subdomain = getSubdomain();
    const appTitle = capitalize(subdomain);
    manifest.name = appTitle;
    manifest.short_name = appTitle;
    manifest.description = appTitle;

    let icons = [];
    icons.push({
        "src": `${hosturl}/energreen_64.png`,
        "sizes": "64x64",
        "type":"image/png"
    });
    icons.push({
        "src": `${hosturl}/energreen_192.png`,
        "sizes": "192x192",
        "type":"image/png"
    });
    icons.push({
        "src": `${hosturl}/energreen.png`,
        "sizes": "512x512",
        "type":"image/png",
        "purpose":"any"
    });

    manifest.icons = icons;
    let content = encodeURIComponent(JSON.stringify(manifest));
    let url = "data:application/manifest+json,"+content;
    return url;
}

const setIndexIcons = () => {
    let hosturl = getHostUrl();
    document.querySelector('#icon_48').setAttribute('href', `${hosturl}/energreen_48.png`);
    document.querySelector('#icon_512').setAttribute('href', `${hosturl}/energreen_512.png`)
    document.querySelector('#apple_touch').setAttribute('href', `${hosturl}/energreen_192.png`)
    document.querySelector('#touch_icon').setAttribute('href', `${hosturl}/energreen_192.png`)
}

const setManifest = () => {
    let url = getManifest();
    document.querySelector('#my-manifest').setAttribute('href', url);
    document.querySelector('#app_title').innerHTML = capitalize(getSubdomain());

    setIndexIcons();
};

(async () => {
    await initKeycloak();

    const app = createApp(App);
    registerPlugins(app);

    app.mount('#app');

    setManifest()
})();
