import { getApiRoot } from '@/helpers/api'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { getLocalstorageItem, setLocalstorageItem } from '@/helpers/localStorage'
import { getSubdomain } from '@/helpers/util';
import apiClient from '@plugins/api-client'
import i18n from '@i18n';
import { getKeycloak } from '@/helpers/keycloak';
import { useApi } from "@/composables/useApi";

const { t } = i18n.global;


export const OfferTypes = {
    SolarPanels: 1,
    SolarBoilers: 2,
    HeatPumps: 3,
    HeatPumpBoiler: 4,
    Other: 5,
    Batteries: 6,
    ChargingStations: 10,
    Control: 15
}

export const OfferTypesTextKeys = {
    1: "solarPanels",
    2: "solarBoilers",
    3: "heatpumps",
    4: "heatpumpBoilers",
    5: "other",
    6: "batteries",
    10: "chargingStations",
    15: "control"
}

const OfferStatuses = {
    New: 1,
    Sent_Initial_Offer: 2,
    Make_Dedicated_Offer: 3,
    Sent_Dedicated_Offer: 4,
    Visit: 5,
    Sent_Final_Offer: 6,
    Completed: 7,
    Canceled: 8,
    Make_Final_Offer: 9,
    Not_Enough_Information: 11
}



export const useOfferStore = defineStore('offerStore', () => {
    const offer = ref(null);
    const versions = ref(null);
    const StatusExpirationDays = 10;
    const { apiPost } = useApi()

    async function getOfferByID(id, versionNumber){
        try{
            const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
            const response = await apiClient.get(
                `${getApiRoot()}/offers/${id}/versions/${versionNumber}`,
                { headers }
            )
            if(response.data.errors.length == 0){
                offer.value = response.data.data;
            }
            else{
                throw new Error(response.data.errors)
            }
        } catch(error){
            throw error;
        }
    }

    async function getOfferVersions(id){
        try{
            const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
            const response = await apiClient.get(
                `${getApiRoot()}/offers/${id}/versions`,
                { headers }
            )
            if(response.data.errors.length == 0){
                versions.value = response.data.data;
             }
             else{
                 throw new Error(response.data.errors)
             }
        } catch(error){
            throw error;
        }
    }

    async function signOffer(offerID, base64image){
        try{
            const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
            const response = await apiClient.post(
                `${getApiRoot()}/offers/${offerID}/sign`,
                {
                    file: base64image.data
                },
                { headers }
            )
            if(response.status === 200){
                return true;
            }
            else{
                throw new Error(response.data.message);
            }
        } catch(error){
            throw error;
        }
    }

    async function getOfferStatuses() {
        let OfferStatuses = getLocalstorageItem("OfferStatuses");
        if(OfferStatuses !== undefined){
            return OfferStatuses;
        }
        return (await parseStatusResponse())?.OfferStatuses;
    }

    async function getInitialOfferStatus() {
        let InitialOfferStatus = getLocalstorageItem("InitialOfferStatus");
        if(InitialOfferStatus !== undefined) {
            return InitialOfferStatus;
        }
        return (await parseStatusResponse())?.InitialOfferStatus;
    }

    async function getFinalOfferStatus() {
        let FinalOfferStatus = getLocalstorageItem("FinalOfferStatus");
        if(FinalOfferStatus !== undefined) {
            return FinalOfferStatus;
        }
        return (await parseStatusResponse())?.FinalOfferStatus;
    }

    async function parseStatusResponse(){
        try{
            const response = await apiClient.get(
                `${getApiRoot()}/offers/statuses`, {
                    params: {
                      subDomain: getSubdomain(),
                      lang: "nl-BE"
                    }
                }
            )
            if(response.data.errors.length == 0){
                let OfferStatuses = {};
                let InitialOfferStatus = null;
                let FinalOfferStatus = null;
                const data = response.data.data;
                for(const status of data) {
                    OfferStatuses[status.status.split(' ').join('_')] = status.id;
                }
                InitialOfferStatus = data.filter(status => { return status.initial})[0]?.id;
                FinalOfferStatus = data.filter(status => { return status.final})[0]?.id;

                setLocalstorageItem("OfferStatuses", OfferStatuses, StatusExpirationDays);
                setLocalstorageItem("InitialOfferStatus", InitialOfferStatus, StatusExpirationDays);
                setLocalstorageItem("FinalOfferStatus", FinalOfferStatus, StatusExpirationDays);

                return {
                    OfferStatuses: OfferStatuses,
                    InitialOfferStatus: InitialOfferStatus,
                    FinalOfferStatus: FinalOfferStatus
                }
            }
            else{
                throw new Error(response.data.errors)
            }
        } catch(error){
            throw error;
        }

    }

    async function storeUpsell(type, name, file) {
        return await apiPost(`offers/${offer.value.offerId}/upsale`, {
            type,
            name,
            file
        }, 'post');
    }

    return {
        offer,
        versions,
        getOfferStatuses,
        getInitialOfferStatus,
        getFinalOfferStatus,
        getOfferByID,
        getOfferVersions,
        signOffer,
        storeUpsell
    }
})
