export function triggerRipple($el) {
    let ev = new Event("mousedown")
    let offset = $el.getBoundingClientRect()
    ev.clientX = offset.left + offset.width/2
    ev.clientY = offset.top + offset.height/2
    $el.dispatchEvent(ev)

    setTimeout(function() {
      $el.dispatchEvent(new Event("mouseup"))
    }, 300)
}

export function roundTo2Decimals(num){
  return +(Math.round(num + "e+2")  + "e-2");
}

//#region Subdomains

export function getSubdomain(){
  // Return a default subdomain on localhost
  if(import.meta.env.VITE_NAME === 'local'){
    return import.meta.env.VITE_SUBDOMAIN;
  }
  let host = window.location.host;
  return host.split('.')[0];
}

export function hasSubdomain(){
  // Using subdomains on localhost doesn't work so just pretend it does
  if(import.meta.env.VITE_NAME === 'local'){
    return true;
  }
  let host = window.location.host;
  let hostParts = host.split(import.meta.env.VITE_DOMAIN);

  return hostParts.length == 2 && hostParts[0] != "";
}

//#endregion

export function handleDownload(fileName, data){
  const href = URL.createObjectURL(data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export const FileTypes = {
  ArrayBuffer: 0,
  DataURL: 1,
  Text: 2
}

export function readUploadedFile(inputFile, fileType = FileTypes.Text) {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    switch(fileType){
      case FileTypes.ArrayBuffer:
        temporaryFileReader.readAsArrayBuffer(inputFile);
        break;
      case FileTypes.DataURL:
        temporaryFileReader.readAsDataURL(inputFile);
        break;
      case FileTypes.Text:
        temporaryFileReader.readAsText(inputFile);
        break;
    }
  });
};

export function toDisplayDateFormat(date){
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.toLocaleString('default', { month: 'short' });
  const year = dateObj.getFullYear();
  return day + ' ' + month + ' ' + year;
}

/**
 * Convert nested json to flat json using dot notation.
 * @param json
 * @returns {{}}
 */
export function JSONNestedToDot(json) {
    const res = {};
    (function recurse(json, current) {
        for(const key in json) {
            const value = json[key];
            const newKey = (current ? current + "." + key : key);  // joined key with dot
            if(value && typeof value === "object") {
                recurse(value, newKey);  // it's a nested object, so do it again
            } else {
                res[newKey] = value;  // it's not an object, so set the property
            }
        }
    })(json);
    return res
}

/**
 * Convert flat json object using dotted keys to nested object.
 * @param input
 * @returns {{}}
 */
export function JSONFlatDotToNested(input) {
    return Object.entries(input).reduce((nestedObject, [key, val]) => {
        if (!key.includes('.')) {
            nestedObject[key] = val;
            return nestedObject;
        }
        const keys = key.split('.');
        const lastKey  = keys.pop();
        const lastObj = keys.reduce((acc, key) => {
            // Create an object at this key if it doesn't exist yet:
            if (!acc[key] || typeof acc[key] === 'string') {
                acc[key] = {};
            }
            return acc[key];
        }, nestedObject);

        lastObj[lastKey] = val;
        return nestedObject;
    }, {});
}

/**
 * Deep merge of 2 objects
 * @param {Object} obj1
 * @param {Object}obj2
 * @returns {Object}
 */
export function deepMerge(obj1, obj2) {
    for (let key in obj2) {
        if (obj2[key] instanceof Object && obj1[key] instanceof Object) {
            obj1[key] = deepMerge(obj1[key], obj2[key]);
        } else {
            obj1[key] = obj2[key];
        }
    }
    return obj1;
}

export function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
