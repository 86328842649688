import {getKeycloak} from "@/helpers/keycloak";
import { handleDownload } from "@/helpers/util";
import { useSnackbarStore } from "@/store/snackbar";
import { useI18n } from "vue-i18n";
import apiClient from '@/plugins/api-client'

export function useDownloads() {
    const i18n = useI18n();
    const snackbarStore = useSnackbarStore();
    const { showError } = snackbarStore;

    function openInNewTab(url){
        window.open(url, '_blank').focus();
    }

    async function generateHappERPFile(downloadDocument){
        downloadDocument.downloading = true;
        try{
            const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
            const response = await apiClient.get(
                downloadDocument.url,
                {
                    responseType: 'blob',
                    headers
                }
            )
            if(response.status === 200){
                handleDownload(`${downloadDocument.text}-${downloadDocument.id}.${downloadDocument.fileExtension}`, response.data)
            }
            else{
                showError(i18n.t('fileError'));
            }
        } catch(error){
            console.log({ error })
            showError(i18n.t('fileError'));
        }
        downloadDocument.downloading = false;
    }

    async function download(downloadDocument) {
        if(!downloadDocument.url){
            return;
        }
        if(downloadDocument.bucketStorage){
            openInNewTab(downloadDocument.url);
        }
        else{
            await generateHappERPFile(downloadDocument);
        }

    }

    return {
        openInNewTab,
        generateHappERPFile,
        download
    }
}
