import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { getLocalstorageItem, setLocalstorageItem } from "@/helpers/localStorage";
import router from '@/router';

export const useNotificationStore = defineStore('notificationStore', () => {
    const notifications = ref([]);
    const hasNewNotifications = ref(false)


    const notificationsCount = computed(() => notifications.value?.length)

    const hasNotifications = computed(() =>  notificationsCount.value > 0)

    function getNotifications() {
        notifications.value = getLocalstorageItem('notifications') || [];
    }

    function setNotifications(notifications = []) {
        setLocalstorageItem('notifications', notifications)
        notifications.value = notifications;
    }

    function addNotification(notification) {
        notifications.value.unshift(notification);
        setLocalstorageItem('notifications', notifications.value)
        hasNewNotifications.value = true;
    }

    function addNotificationToStorage(notification) {
        const notifications =  getLocalstorageItem('notifications') || [];

        notifications.unshift(notification);

        setLocalstorageItem('notifications', notifications);
    }

    function removeNotification(index) {
        notifications.value.splice(index, 1);

        setLocalstorageItem('notifications', notifications.value)
    }

    function removeNotifications() {
        setNotifications([]);
    }

    function notificationAction(notificationData) {
        if(! notificationData) return;

        const action = notificationData.action

        switch (action) {
            case 'offerDetail':
                router.push({
                    name: action,
                    params: {
                        id: notificationData.id,
                        version: notificationData.version,
                    }
                })
                break;
            case 'issueDetail':
                router.push({
                    name: action,
                    params: {
                        issue_id: notificationData.id
                    }
                })
                break;
            default:
                router.push({ name: action })
        }
    }

    return {
        notifications,
        notificationsCount,
        hasNotifications,
        hasNewNotifications,
        getNotifications,
        setNotifications,
        addNotification,
        removeNotification,
        removeNotifications,
        notificationAction
    }
})
